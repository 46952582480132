@import "../../../Assests/Styles/importFiles";
.user-detail-component {
    display: flex;
    width: 95%;
    padding-top: 3%;
    margin-left: 3%;
}

.view-resume-button{
    margin: 5% 0 0 32%;
}

.company-list .no-dp {
    align-items: normal;
}
