@import "../../Assests/Styles/importFiles";
.page-column {
  flex: 1;
  display: flex;
  flex-flow: column;
  padding-top: 10px;
  padding-left: 10px;
  height: 100%;
  color:$dark-blue;
  -webkit-transition: all .2s;
  transition: all .2s;
  overflow: hidden;
  @include breakpoint(screen991) {
    min-height: 300px;
    height: auto;
    .collapseBtn{
      transform: rotate(90deg);
    }
    .collapseBtn.expandBtn {
      transform: rotate(270deg);    
    }
  }
  .column-head {
    display: flex;
    flex-flow: row;
    max-height: 55px;
  }
  .column-body {
    flex: 1;
    margin-top: 10px;
    background: $blue-white;
    border-radius: 3px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  &.col-1 {
    @include breakpoint(screen991) {
      flex: auto;
    }
  }
  &.col-2 {
    @include breakpoint(screen991) {
      flex: auto;
    }
  }
  &.col-3 {
    @include breakpoint(screen991) {
      flex: auto;
      width: 100%;
    }
  }
  .column-collapsed {
    display: none;
    flex: 1;
    flex-flow: column;
    position: relative;
  }

  .expand-heading {
    cursor: pointer;
    background: $light-blue;
    color: $white;
    border-radius: 4px;
    text-align: center;
    font-size: 15px;
    flex: 1;
    margin: 10px 0 0;
    display: flex $imp;
    align-items: center;
    justify-content: center;
    transform: rotateZ(180deg);
    writing-mode: vertical-rl;
    outline: none;

    @include breakpoint(screen991) {
      writing-mode: horizontal-tb;
      transform: none;
      margin: 0 10px 0 0;
      order: -1;
    }
  }
  &.page-column-collapse {
    flex: none;
    width: auto;
    @include breakpoint(screen991) {
      align-self: flex-start;
      width: calc(100% - 10px);
      height: auto;
      min-height: 0;
    }
    .column-head,
    .column-body {
      display: none;
    }
    .column-collapsed {
      display: flex;
      @include breakpoint(screen991) {
        flex-flow: row;
      }
    }
  }
}

@-moz-document url-prefix() {
  .page-column-collapse {
    width: 55px!important;   /* Apply 55px width only for Firefox */
  }
}