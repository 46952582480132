@import "../../Assests/Styles/importFiles";

.modal {
  width: 500px;
  background: white;
  border: 1 px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -0.5rem 0.5rem 2rem black;
  opacity: 1;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: visible;
  position: absolute;
  z-index: 100;
  .model-close{
    float: right;
    .closeBtn {
      align-self: flex-start;
      width: 25px;
      background: $red;
      padding: 0;
      margin-left: 10px;
      border: 1px solid transparent;
      border-radius: 50%;
      height: 25px;
      outline: none;
      &:before {
          @extend %fi;
          font-size: 20px;
          content: $fi-close;
          color: darken($white, 20);
          vertical-align: middle;
          margin: -1px 0px 0 1px;
          box-sizing: border-box;
      }
}
  }
  
  .login-submit-row{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .submit-button{
      align-self: center;
      line-height: 2.0;
      border: 1px transparent;
      background-color: #37aeee;
      border-radius: 3px;
      font-size: 13px;
      max-width: 80px;
      color: ivory;
      min-width: 70px;
      &:hover {
        background-color: rgb(216, 216, 216);
        color: ivory;
      }
    }
    .forgot-label{
      margin-top: 20px;
      margin-right: 30px;
      font-size: 12px;
      font-weight: bold;
    }
    .email-forgot-label {
      min-width: 60px;
      font-size: 14px;
      margin-top: 8px;
      display: flex;
      color: $purple4;
      
    }
  } 
}

.modal.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 black;
}

// .modal h2{
//     border-bottom:1px solid #ccc;
//     padding: 1rem;
//     margin: 0;
// }

.modal .content {
  padding: 1rem;
}

.modal .actions {
  border-bottom: 2.5px solid #ccc;
  background: $light-blue;
  padding: 20px;
  p {
    font-size: 20px;
    color: #fefefe;
    float: left;
    margin: -10px;
  }

}
