@import "../../Assests/Styles/importFiles";

.navigation {
  background: $darkBlue;
  overflow: auto;
  overflow-x: hidden;
  min-width: 230px;
  height: 91vh;
  @include breakpoint(screen1240) {
    margin-top: 10px;
    width: 48px;
    min-width: 48px;
    z-index: 100;
    box-shadow: 0 0 7px 1px $darkBlue;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
  }

  &.nav-shrink-container {
    width: 48px;
    min-width: 48px;
    flex: none;
    overflow: hidden;
    @include breakpoint(screen1240) {
      min-width: 200px;
      width: 200px;
      overflow: auto;
    }
  }

  .nav-help-icon-container {
    position: relative;
    &.nav-help-icon-shrink-container {
      position: unset;
    }
    .help-icon {
      top: 2px;
      left: 5px;
      right: 0;
    }
  }
  .leftNavIcon {
    @include border-radius(50%);
    min-width: 30px;
    text-align: center;
    height: 30px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    background-color: $font-color-light_grey_purple;
    &:before {
      content: "";
      display: inline-block;
      margin: 0 auto;
      @extend %Im;
      font-size: 13px;
      color: $purple2; // content: $fi-profile;
    }
  }
  .sub-nav-wrapper {
    padding-left: 30px;
  }

  .arrowCls {
    &:after {
      // @include spriteIcon(down, $imp);
      background: url("../../Assests/Images/down.png") no-repeat center center;
      width: 8px;
      height: 5px;
      content: "";
      position: absolute;
      right: 15px;
      top: 50%;
      margin-top: -2px;
    }
  }

  .DashboardBtn {
    color: $font-color-light_grey_purple $imp;
    background: $green $imp;
    .helpMsg {
      vertical-align: text-bottom;
    }
  }

  .DashboardCompanyBtn {
    background: $green;
  }

  .DashboardFreelanceBtn {
    @extend .DashboardCompanyBtn;
    background: $blue;
    color: $font-color-light_grey_purple $imp;
    .dashboardIcon {
      &:before {
        content: $fi-DashboardFreelance;
        color: $blue;
      }
    }
  }

  .dashboardIcon {
    @extend .leftNavIcon;
    background-color: $font-color-light_grey_purple $imp;
    &:before {
      // @extend %fi;
      content: $fi-DashboardCompany;
      color: $green;
      font-size: 14px;
    }
  }

  .featuresIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Shortlist;
      color: $darkBlue;
      font-size: 13px;
    }
  }

  .lookupsIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .adminManagementIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-settingIcon;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .adminManagementAdminIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-settingIcon;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .adminManagementFeaturesIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .adminManagementEventsIcon {
    @extend .leftNavIcon;
    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .adminManagementRoleIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .lookupCorrelationIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Collaboration;
      color: $darkBlue;
      font-size: 16px;
    }
  }

  .systemLabelsIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Keyword;
      color: $darkBlue;
      font-size: 16px;
    }
  }
  .messageIcon {
    @extend .leftNavIcon;

    &:before {
      // @extend %fi;
      content: $fi-Message;
      color: $darkBlue;
      font-size: 11px;
    }
  }

  .emailIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }

  .embarkIcon {
    @extend .leftNavIcon;
    background-color: $purple;
    &:before {
      // @extend %fi;
      content: $fi-DashboardCompany;
      color: $darkBlue;
      font-size: 14px;
    }
  }
  .philipIcon {
    @extend .leftNavIcon;
    background-color: $purple;
    &:before {
      // @extend %fi;
      content: $fi-profile;
      color: $darkBlue;
      font-size: 14px;
    }
  }
  .createMessageIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-createMessage;
      color: $darkBlue;
      font-size: 13px;
    }
  }

  .inboxMessageIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-inboxMessage;
      color: $darkBlue;
      font-size: 13px;
    }
  }

  .sentMessageIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-sentMessage;
      color: $darkBlue;
      font-size: 13px;
    }
  }

  .createOpportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-createOpportuonity;
      color: $darkBlue;
      font-size: 15px;
    }
  }

  .sendOpportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Opportunity;
      color: $darkBlue;
      font-size: 14px;
    }
  }

  .draftOpportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Opportunity;
      color: $darkBlue;
      font-size: 14px;
    }
  }

  .opportunityIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Opportunity;
      color: $darkBlue;
      margin-top: -2px;
      font-size: 15px;
    }
  }

  .collaborationIcon {
    @extend .leftNavIcon;

    &:before {
      // @extend %fi;
      content: $fi-Collaboration;
      color: $darkBlue;
      font-size: 14px;
    }
  }

  .resumeIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-resume;
      color: $darkBlue;
      font-size: 14px;
    }
  }

  .companyIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-industry;
      color: $darkBlue;
      font-size: 13px;
    }
  }
  .presentIcon {
    @extend .leftNavIcon;

    &:before {
      // @extend %fi;
      content: $fi-present;
      color: $darkBlue;
      font-size: 14px;
    }
  }

  .statisticsIcon {
    @extend .leftNavIcon;

    &:before {
      // @extend %fi;
      content: $fi-statistics;
      color: $darkBlue;
      font-size: 14px;
    }
  }
  .feedbackIcon {
    @extend .leftNavIcon;

    &:before {
      // @extend %fi;
      content: $fi-feedback;
      color: $darkBlue;
      font-size: 14px;
    }
  }

  .settingIcon {
    @extend .leftNavIcon;

    &:before {
      // @extend %fi;
      content: $fi-About;
      color: $darkBlue;
      font-size: 14px;
    }
  }
  .adminconfigurationIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .ConfigurationCookieAgreementIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .ConfigurationLegalNoticeIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .ConfigurationPrivacyPolicyIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .ConfigurationTermsAndConditionsIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .configurationVersionIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .adminProductIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }
  .adminFeedbackIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }

  .adminbusinessUserIcon {
    @extend .leftNavIcon;

    &:before {
      content: $fi-Search;
      color: $darkBlue;
      font-size: 18px;
    }
  }

  // .snapshotIcon {
  //   @extend .leftNavIcon;

  //   &:before {
  //     // @extend %fi;
  //     content: $fi-About;
  //     content: $fi-snapshot;
  //     color: $darkBlue;
  //     font-size: 14px;
  //   }
  // }
  // .tictellIcon {
  //   @extend .leftNavIcon;

  //   &:before {
  //     // @extend %fi;
  //     content: $fi-About;
  //     content: $fi-tictell-colored;
  //     color: $darkBlue;
  //     font-size: 14px;
  //   }
  // }
}
