@import "../../../Assests/Styles/importFiles";
.selected-logs {
  display: flex;
  width: 100%;

  .MsgWrapper {
    width: 100%;
  }

  .roundeImg {
    max-width: 100%;
    height: 105px;
  }

  .titleLabel {
    text-align: center;
    margin: 5% 0 5% 0;
    text-transform: uppercase;
  }

  .leftLabel {
    min-width: 150px;
    display: table-cell;
  }

  .rightLabel {
    width: 100%;
    padding: 6px 10px;
    display: table-cell;
    border-radius: 3px;
    word-break: break-all;
  }

  .dashItemImg {
    width: 104px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;
    position: relative;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background: $offwhite2;
  }
  .no-dp {
    .roundeImg {
      max-width: 40px;
    }
  }
}
