@import "../../../Assests/Styles/importFiles";
@import "../../../Assests/Styles/common";

.input-select-field {
  color: #0267aa;
  height: auto;
  border: 2px solid $light-blue;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 8px;
  h6 {
    color: $light-blue;
    width: fit-content;
    margin-top: -10px;
    margin-left: 10px;
    padding-left: 5px;
    padding-right: 5px;
    background: $blue-white;
    margin-block-end: 0.8em;
  }
  .css-1pahdxg-control {
    background: transparent;
    border: none;
  }
  .select-fix {
    .Select-control {
      padding-top: 5px !important;
    }
  }
  .select-input {
    width: 100%;
    &.inValid {
      border: 1px solid $red;
      border-radius: 3px;
    }
    .css-yk16xz-control {
      input {
        background: transparent;
        border: transparent;
      }
    }
    > div:last-of-type {
      height: auto;
      background: #e7f4fd;
      border: none;
    }
    .css-yk16xz-control {
      min-width: 56px;
    }
    div[class~="-control"] {
      background: $white $imp;
      box-shadow: none $imp;
      color: $purple2;
      font-size: 13px;
      border: transparent;
      border-radius: 3px;
      .Select-value {
        line-height: 27px;
      }
    }
    .Select-input {
      color: $blue;
      font-size: 13px;
      height: auto;

      > input {
        min-width: 0;
        padding: 6px 0;
        line-height: 1;
      }
    }
    .Select-placeholder {
      line-height: 30px;
      color: $purple3;
      font-size: 13px;
    }
    .Select-menu-outer {
      border: none;
      &:before {
        content: "";
        box-shadow: rgb(136, 136, 136) 0px 0px 6px;
        position: absolute;
        top: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
      }
      .Select-menu {
        background: $white;
      }
    }
    .Select-noresults {
      padding: 6px 10px;
      font-size: 13px;
    }
    .Select-option {
      padding: 5px 6px;
      font-size: 13px;
    }
  }
}

.list-wrapper .list-item.selected-item {
  background: #dfd9f7;
}

.statisticsText {
  text-align: center;
}

.main-div-reports {
  width: 100%;
  margin: 0 5px 0 0;
  display: flex;
  .page-column {
    // width: 48%;
    .column-body {
      padding: 20px;
      .fields-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .labelCalender {
        display: flex;
        max-width: 100%;
        .form-label {
          min-width: 25%;
          font-size: 12px;
          margin-top: 8px;
          display: -webkit-flex;
          display: flex;
          color: #9783cf;
        }
        .datepicker-input {
          padding: 0px 10px;
          width: 70%;
        }
      }
      .chart {
        width: 70%;
        margin: 5% 0 0 15%;
      }
    }
  }
}
